import {HttpClient, HttpParams} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {QueryFormattingUtility} from "../models/filtration/query-formatting.utility";
import {PageableRequest} from "../models/pageable/pageable.request";
import {InvokableFiltration} from "../models/filtration/filtration";
import {PageableResponse} from "../models/pageable/pageable.response";
import {DistrictResponse} from "../models/district.response";
import {MunicipalityResponse} from "../models/municipality.response";
import {SettlementResponse} from "../models/settlement.response";
import {LocationResponse} from "../models/location/location.response";
import {ValidLocationTokenResponse} from "../models/location/valid-location-token.response";
import {ResidentialAreaResponse} from "../models/residential-area.response";
import {
  AddressFormComponent
} from "../../private/profile/profile-edit/profile-edit-addresses/address-form/address-form.component";
import {DialogService} from "primeng/dynamicdialog";
import {ProfileService} from "../../private/profile/profile.service";
import {Store} from "@ngrx/store";
import {ProfileState} from "../../private/profile/store/profile.state";
import {UpdateLocationRequest} from "../models/location/update-location.request";
import {CreateLocationRequest} from "../models/location/create-location.request";

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  constructor(
    private queryFormattingUtility: QueryFormattingUtility,
    private http: HttpClient,
    private dialogService: DialogService,
    private profileService: ProfileService,
    private profileStore: Store<ProfileState>
  ) {
  }

  getDistricts(
    pageable: PageableRequest,
    filtration: InvokableFiltration
  ): Observable<PageableResponse<DistrictResponse>> {
    return this.http.get<PageableResponse<DistrictResponse>>(
      '/api/locations/districts'
      , {
        params: this.queryFormattingUtility.formatQuery(pageable, filtration)
      }
    );
  }

  getSettlements(
    pageable: PageableRequest | null = null,
    filtration: InvokableFiltration | null = null
  ): Observable<PageableResponse<SettlementResponse>> {
    return this.http.get<PageableResponse<SettlementResponse>>(
      '/api/locations/settlements'
      , {
        params: this.queryFormattingUtility.formatQuery(pageable, filtration)
      }
    );
  }

  getMunicipalities(
    pageable: PageableRequest | null = null,
    filtration: InvokableFiltration | null = null
  ): Observable<PageableResponse<MunicipalityResponse>> {
    return this.http.get<PageableResponse<MunicipalityResponse>>(
      '/api/locations/municipalities'
      , {
        params: this.queryFormattingUtility.formatQuery(pageable, filtration)
      }
    );
  }

  getResidentailAreas(
    pageable: PageableRequest | null = null,
    filtration: InvokableFiltration | null = null
  ): Observable<PageableResponse<ResidentialAreaResponse>> {
    return this.http.get<PageableResponse<ResidentialAreaResponse>>(
      '/api/locations/residential-areas'
      , {
        params: this.queryFormattingUtility.formatQuery(pageable, filtration)
      }
    );
  }

  getLocationToken(locationId: number): Observable<LocationResponse> {
    return this.http.get<LocationResponse>(
      '/api/locations/images/' + locationId
    );
  }

  checkLocationTokenValidity(token: string): Observable<ValidLocationTokenResponse> {
    let params = new HttpParams();
    params = params.set('token', token);

    return this.http.get<ValidLocationTokenResponse>(
      '/api/locations/images/token', {params}
    );
  }

  createEditLocation(currentLocation: LocationResponse | null = null): Observable<LocationResponse> {
    return new Observable<LocationResponse>((subscriber) => {
      this.dialogService.open(AddressFormComponent, {
        header: 'Адрес',
        data: currentLocation,
      }).onClose.subscribe({
        next: (value: LocationResponse | null) => {
          if (!value) {
            subscriber.error('No location provided');
            subscriber.complete();
            return;
          }

          const locationChange$ = currentLocation
            ? this.updateLocation(UpdateLocationRequest.fromResponse(value), value.id)
            : this.createLocation(CreateLocationRequest.fromResponse(value));

          locationChange$.subscribe(location => {
            subscriber.next(LocationResponse.create(location));
            subscriber.complete();
            return subscriber.next();
          });
        },
        error: (err) => {
          subscriber.error(err);
        }
      });
    });
  }

  public updateLocation(request: UpdateLocationRequest, id: number): Observable<LocationResponse> {
    return this.http.patch<LocationResponse>('api/locations/' + id, request);
  }

  public createLocation(request: CreateLocationRequest) {
    console.log(request)
    return this.http.post<LocationResponse>('api/locations', request);
  }
}
