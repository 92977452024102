import {Component, Input} from '@angular/core';
import {CheckboxModule} from "primeng/checkbox";
import {RadioButtonModule} from "primeng/radiobutton";
import {FormArray, FormControl, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {DeliveryType, Specifics} from "../../order-stepper.constants";

@Component({
  selector: 'bop-market-order-info-options',
  standalone: true,
  imports: [
    CheckboxModule,
    RadioButtonModule,
    ReactiveFormsModule
  ],
  templateUrl: './market-order-info-options.component.html',
  styleUrl: './market-order-info-options.component.scss'
})
export class MarketOrderInfoOptionsComponent {
  protected readonly specificsList = Object.values(Specifics);
  protected readonly deliveryTypesList = Object.values(DeliveryType);

  @Input() deliveryTypeForm!: FormGroup;
  @Input() specificsForm!: FormGroup<{specifics: FormArray<FormControl<string>>}>;
}
