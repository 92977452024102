<div class="flex pt-4 justify-content-between">
  @if (!isFirstStep) {
    <p-button [label]="backwardText" icon="pi pi-arrow-left" (onClick)="onBackward.emit()"/>
  }

  @if (!isLastStep) {
    <p-button [label]="forwardText" icon="pi pi-arrow-right" iconPos="right" (onClick)="onForward.emit()"/>
  }

  @if (isLastStep) {
    <p-button [label]="confirmText" icon="pi pi-times" (onClick)="onConfirm.emit()"/>
  }
</div>
