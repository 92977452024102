import {Component, Input} from '@angular/core';
import {PackagingResponse} from "../packaging.response";
import {ProductClassComponent} from "../../../shared/components/product-class/product-class.component";
import {SharedModule} from "primeng/api";
import {TableModule} from "primeng/table";
import {FileUrlPipe} from "../../../shared/pipes/file-url.pipe";
import {ButtonModule} from "primeng/button";
import {InputNumberModule} from "primeng/inputnumber";
import {FormsModule} from "@angular/forms";
import {Store} from "@ngrx/store";
import {addItemToCart} from "../store/cart.actions";
import {ShoppingCartRequest} from "../shopping-cart.request";

@Component({
  selector: 'bop-market-packagings-list',
  standalone: true,
  imports: [
    ProductClassComponent,
    SharedModule,
    TableModule,
    FileUrlPipe,
    ButtonModule,
    InputNumberModule,
    FormsModule
  ],
  templateUrl: './market-packagings-list.component.html',
  styleUrl: './market-packagings-list.component.scss'
})
export class MarketPackagingsListComponent {
  @Input() public packagings: PackagingResponse[] = [];

  public quantityValue: number = 1;

  constructor(private store: Store) {
  }

  public addToCart(packaging: any) {
    const newShoppingCartItem: ShoppingCartRequest = {
      packagingId: packaging.id,
      quantity: this.quantityValue,
      discount: 0
    }

    this.store.dispatch(addItemToCart({
      item: newShoppingCartItem
    }));
  }

}
