import {Component, inject, OnInit, ViewChild} from '@angular/core';
import {StepperModule, Stepper} from "primeng/stepper";
import {ButtonModule} from "primeng/button";
import {CardModule} from "primeng/card";
import {CommonModule} from "@angular/common";
import {MarketOrderInfoRecipientComponent} from "./market-order-info-recipient/market-order-info-recipient.component";
import {
  MarketOrderInfoDeliveryMethodComponent
} from "./market-order-info-delivery-method/market-order-info-delivery-method.component";
import {MarketOrderInfoOptionsComponent} from "./market-order-info-options/market-order-info-options.component";
import {
  MarketOrderInfoPaymentMethodComponent
} from "./market-order-info-payment-method/market-order-info-payment-method.component";
import {MarketOrderInfoReferenceComponent} from "./market-order-info-reference/market-order-info-reference.component";
import {FormArray, FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {Store} from "@ngrx/store";
import {ProfileState} from "../../profile/store/profile.state";
import {selectCurrentUser} from "../../profile/store/profile.reducer";
import {
  BackwardForwardButtonsComponent
} from "../../../shared/components/backward-forward-buttons/backward-forward-buttons.component";
import {getShoppingCart} from "../store/cart.actions";
import {selectShoppingCart} from "../store/cart.reducer";
import {MessageService} from "primeng/api";

@Component({
  selector: 'bop-market-order-info',
  standalone: true,
  imports: [
    StepperModule,
    ButtonModule,
    CardModule,
    CommonModule,
    MarketOrderInfoRecipientComponent,
    MarketOrderInfoDeliveryMethodComponent,
    MarketOrderInfoOptionsComponent,
    MarketOrderInfoPaymentMethodComponent,
    MarketOrderInfoReferenceComponent,
    BackwardForwardButtonsComponent
  ],
  templateUrl: './market-order-info.component.html',
  styleUrl: './market-order-info.component.scss'
})
export class MarketOrderInfoComponent implements OnInit {
  @ViewChild('orderStepper') orderStepper!: Stepper;

  public userStore = inject(Store<ProfileState>);
  public referenceDataForm: FormGroup | undefined;
  public totalPrice: number = 0;

  public messageService = inject(MessageService);

  recipientInfoForm = new FormGroup({
    firstName: new FormControl(),
    lastName: new FormControl(),
    email: new FormControl(),
    phoneNumber: new FormControl()
  })

  deliveryTypeForm = new FormGroup({
    deliveryType: new FormControl()
  })

  specificsForm = new FormGroup({
    specifics: new FormArray([ new FormControl(), new FormControl(), new FormControl(), new FormControl(), new FormControl(),  new FormControl()])
  })

  deliveryMethodForm = new FormGroup({
    address: new FormControl(),
    details: new FormArray([ new FormControl(), new FormControl(), new FormControl(), new FormControl(), new FormControl(),  new FormControl()])
  })

  paymentMethodForm = new FormGroup({
    paymentMethod: new FormControl()
  })

  constructor(
    private formBuilder: FormBuilder,
    private store: Store
  ) {
  }

  ngOnInit() {
    this.userStore.select(selectCurrentUser).subscribe(user => {
      this.recipientInfoForm.patchValue({
        firstName: user?.firstName,
        lastName: user?.lastName,
        email: user?.email,
        phoneNumber: user?.phoneNumbers[0].number
      })
    });

    this.store.dispatch(getShoppingCart());

    this.store.select(selectShoppingCart).subscribe((cart) => {
      if (cart) {
        this.totalPrice = cart.total;
      }
    });
  }

  forwardStepper(index: number) {
    this.orderStepper.activeStep = index + 1;
  }

  backwardStepper(index: number) {
    this.orderStepper.activeStep = index - 1;
  }

  getSpecifics() {
    return this.specificsForm.controls.specifics.getRawValue();
  }

  combineAllFormsData(index: number) {
    this.forwardStepper(index);

    this.referenceDataForm = this.formBuilder.group({
      recipientInfo: this.recipientInfoForm,
      deliveryType: this.deliveryTypeForm,
      specifics: this.specificsForm,
      deliveryMethod: this.deliveryMethodForm,
      paymentMethod: this.paymentMethodForm
    });
  }

  confirmOrder() {
    this.messageService.add({severity: 'success', summary: 'Поръчката е изпратена успешно!'});
  }
}
