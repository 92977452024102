import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {ShoppingCartResponse} from "./shopping-cart.response";
import {ShoppingCartRequest} from "./shopping-cart.request";
import {OrderResponse} from "./order.response";
import {OrderRequest} from "./order.request";

@Injectable({providedIn: 'root'})
export class CartService {
  apiCarts: string = 'api/carts';
  apiOrders: string = 'api/orders';

  constructor(private http: HttpClient) {
  }

  getItems(): Observable<ShoppingCartResponse> {
    return this.http.get<ShoppingCartResponse>(this.apiCarts);
  }

  addItem(item: ShoppingCartRequest): Observable<ShoppingCartResponse> {
    return this.http.post<ShoppingCartResponse>(`${this.apiCarts}/items`, item);
  }

  updateItem(itemId: number, updatedItem: ShoppingCartRequest): Observable<ShoppingCartResponse> {
    return this.http.patch<ShoppingCartResponse>(`${this.apiCarts}/items/${itemId}`, updatedItem);
  }

  removeItem(itemId: number): Observable<ShoppingCartResponse>{
    return this.http.delete<ShoppingCartResponse>(`${this.apiCarts}/items/${itemId}`);
  }

  createOrder(order: OrderRequest): Observable<OrderResponse> {
    return this.http.post<OrderResponse>(this.apiOrders, order);
  }
}
