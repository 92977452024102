<h2>Специфики</h2>
<form [formGroup]="specificsForm" class="pt-3 flex flex-column gap-2">
  @for (specific of specificsList; track specific) {
    <label>
      <p-checkbox [value]="specific"
                  formControlName="specifics"
                  [formControl]="specificsForm.controls.specifics.at(specificsList.indexOf(specific))"/>
      {{ specific }}
    </label>
  }
</form>

<h2 class="mt-7">Тип доставка</h2>
<form [formGroup]="deliveryTypeForm" class="pt-3 flex flex-column gap-2">
  @for (type of deliveryTypesList; track type) {
      <div class="ui-g-12">
          <p-radioButton name="deliveryType"
                         [value]="type"
                         [label]="type"
                         formControlName="deliveryType">
          </p-radioButton>
      </div>
  }
</form>
