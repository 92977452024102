<p-card class="shopping-cart-item m-3">
  <div class="flex flex-row align-items-center justify-content-between">
    <div class="flex flex-row align-items-center">
      <img
        class="w-5rem mt-2 mr-3"
        [alt]="cartItem.product.name"
        [src]="cartItem.item.packageImage.url | fileUrl"/>
      <div>
        <p class="my-2">{{ cartItem.product.name }}</p>
        <p class="my-2">
          Единична цена: {{ cartItem.item.price | price }} лв/{{cartItem.item.packagingType.name}}
        </p>
        <div class="flex flex-row align-items-center">
          <p-inputNumber
            [(ngModel)]="cartItem.quantity"
            (ngModelChange)="onQuantityChange($event, cartItem)"
            [showButtons]="true"
            [min]="1"
            [max]="cartItem.item.stockAvailable"
            [inputStyle]="{width: '5rem'}">
          </p-inputNumber>
          <p class="mx-2">бр</p>
          <p-button icon="pi pi-trash" (click)="removeFromCart(cartItem)"></p-button>
        </div>
      </div>
    </div>
    <div>
      <p class="font-bold m-0">
        {{ (cartItem.quantity * cartItem.item.price) | price }} лв
      </p>
    </div>
  </div>
</p-card>
