import {Component, inject, Input} from '@angular/core';
import {FormArray, FormControl, FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {OriginService} from "../../../../shared/services/creatable-dropdown-service/origin.service";
import {
  CreatableDropdownSingleComponent
} from "../../../../shared/components/creatable-dropdown/creatable-dropdown-single/creatable-dropdown-single.component";
import {
  FormRowWrapperComponent
} from "../../../../shared/components/form-wrapper/form-row-wrapper/form-row-wrapper.component";
import {InputGroupAddonModule} from "primeng/inputgroupaddon";
import {InputGroupModule} from "primeng/inputgroup";
import {CreatableDropdownOutput} from "../../../../shared/components/creatable-dropdown/creatable-dropdown.output";
import {CheckboxModule} from "primeng/checkbox";
import {DeliveryMethod} from "../../order-stepper.constants";

@Component({
  selector: 'bop-market-order-info-delivery-method',
  standalone: true,
  imports: [
    FormsModule,
    CreatableDropdownSingleComponent,
    FormRowWrapperComponent,
    InputGroupAddonModule,
    InputGroupModule,
    CheckboxModule,
    ReactiveFormsModule
  ],
  templateUrl: './market-order-info-delivery-method.component.html',
  styleUrl: './market-order-info-delivery-method.component.scss'
})
export class MarketOrderInfoDeliveryMethodComponent {
  protected readonly deliveryMethodsList = Object.values(DeliveryMethod);

  @Input() deliveryMethodForm!: FormGroup<{details: FormArray<FormControl<string>>, address: FormControl<string>}>;

  originService = inject(OriginService);

  selectAddress($event: CreatableDropdownOutput) {
    this.deliveryMethodForm.controls.address.setValue($event.singleOutput.name)
  }

}
