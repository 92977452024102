<h2>Начин на плащане</h2>
<form [formGroup]="paymentMethodForm" class="pt-3 flex flex-column gap-3">
  @for (payment of paymentMethodsList; track payment) {
    <div class="ui-g-12">
      <p-radioButton [formControlName]="payment.id"
                     [name]="payment.id"
                     [value]="payment.name"
                     [label]="payment.name"
                     [inputId]="payment.id"
                     (onClick)="selectPaymentMethod(payment.name)">
      </p-radioButton>
    </div>
  }
</form>
