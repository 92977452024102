import {Component, inject, OnInit} from '@angular/core';
import {Router, RouterOutlet} from "@angular/router";
import {LayoutStore} from "../shared/store/layout/layout.store";
import {Store} from "@ngrx/store";
import {AuthenticationState} from "../public/authentication/store/authentication.state";
import {MenuItem, PrimeIcons} from "primeng/api";
import {logout} from "../public/authentication/store/authentication.actions";
import {ProfileState} from "./profile/store/profile.state";
import {getMyProfile} from "./profile/store/profile.actions";
import {selectCurrentUser} from "./profile/store/profile.reducer";
import {DialogService} from "primeng/dynamicdialog";
import {TermsOfServiceComponent} from "../shared/components/terms-of-service/terms-of-service.component";

@Component({
  selector: 'bop-private',
  standalone: true,
  imports: [
    RouterOutlet
  ],
  providers: [DialogService],
  templateUrl: './private.component.html',
  styleUrl: './private.component.scss'
})
export class PrivateComponent implements OnInit {
  readonly layoutStore = inject(LayoutStore);
  private termsDialogOpened = false;

  private defaultProfileItems: MenuItem[] = [
    {
      icon: PrimeIcons.PENCIL,
      label: 'Профил',
      routerLink: ['private', 'profile']
    },
    {
      icon: PrimeIcons.SIGN_OUT,
      label: 'Изход',
      command: () => this.authenticationStore.dispatch(logout())
    }
  ];

  private commonHeaderItems: MenuItem[] = [
    {
      icon: PrimeIcons.HOME,
      label: '',
      routerLink: '/private/home',
      style: {
        'border-radius': '50%',
        'height': '2.5rem',
        'background-color': 'var(--yellow-200)',
        'border': 'none',
        'color': '#FFFFFF'
      }
    },
    {
      icon: PrimeIcons.GIFT,
      label: 'ДАРове',
      routerLink: '/private/donations',
    },
    {
      icon: PrimeIcons.SHOPPING_CART,
      label: 'Пазар',
      routerLink: '/private/market',
    }
  ];

  private adminHeaderItems: MenuItem[] =  [
    {
      icon: PrimeIcons.WRENCH,
      label: 'Админ панел',
      routerLink: '/private/admin'
    }
  ];

  private exitHeaderItems: MenuItem[] = [
    {
      icon: PrimeIcons.SIGN_OUT,
      label: '',
      style: {
        'border': 'none'
      },
      command: () => this.authenticationStore.dispatch(logout())
    }
  ]

  constructor(private authenticationStore: Store<AuthenticationState>,
              private profileStore: Store<ProfileState>,
              private dialogService: DialogService,
              private router: Router) {
  }

  public ngOnInit(): void {
    this.layoutStore.setProfileItems(this.defaultProfileItems);

    if (this.router.url.includes('profile/edit/light')) {
      this.dialogService.open(TermsOfServiceComponent, {header: 'Общи условия на БОП', closable: false, width: '90vw'})
      return;
    }

    this.profileStore.dispatch(getMyProfile());

    this.profileStore.select(selectCurrentUser).subscribe(res => {
      if (res?.admin) {
        this.layoutStore.setHeaderItems(this.commonHeaderItems.concat(this.adminHeaderItems).concat(this.exitHeaderItems));
        return;
      } else if (res?.status === 1 || res?.status === 2 ) {
        this.layoutStore.setHeaderItems(this.commonHeaderItems.concat(this.exitHeaderItems));
      }

      if (res?.status === 3) {
        this.router.navigate(['/private/profile/edit']);
      }

      if (res?.status === 3 && !this.termsDialogOpened) {
        this.termsDialogOpened = true;
        this.dialogService.open(TermsOfServiceComponent, {header: 'Общи условия на БОП', closable: false, width: '90vw'});
      }
    })
  }

}
