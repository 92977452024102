<section class="m-3">
  <p-table [value]="allOrdersToTransport">
    <ng-template pTemplate="header">
      <tr>
        <th>№</th>
        <th>Производител</th>
        <th>Продукти</th>
        <th>Получател</th>
        <th>Адрес за доставка</th>
        <th>Тип на доставка</th>
        <th>Тип на плащане</th>
        <th>Специфики</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-order>
      <tr>
        <td>
          {{ order.id }}
        </td>
        <td>{{ order.items[0]?.product.creator.firstName }} {{ order.items[0]?.product.creator.lastName }}</td>
        <td>
          @for (item of order.items; track item) {
            <p>{{ item.product.name }} ({{ item.quantity }} бр.)</p>
          }
        </td>
        <td>{{ order.merchant.firstName }} {{ order.merchant.lastName }}</td>
        <td>{{ order.deliveryAddress.address }}</td>
        <td>{{ order.deliveryAddress.deliveryType }}</td>
        <td>{{ order.deliveryAddress.paymentMethod }}</td>
        <td>{{ order.deliveryAddress.comments }}</td>
      </tr>
    </ng-template>
  </p-table>
</section>
