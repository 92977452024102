import {CartState} from "./cart.state";
import {createFeature, createReducer, on} from "@ngrx/store";
import {
  addItemToCartSuccess,
  getShoppingCart,
  getShoppingCartError,
  getShoppingCartSuccess,
  removeItemFromCart, removeItemFromCartError,
  removeItemFromCartSuccess
} from "./cart.actions";
import {ShoppingCartResponse} from "../shopping-cart.response";

const InitialState: CartState = {
  shoppingCart: new ShoppingCartResponse()
};

const cartFeature = createFeature({
  name: 'Cart',

  reducer: createReducer(
    InitialState,

    on(addItemToCartSuccess, (state, {updatedCart }) => ({
      ...state,
      shoppingCart: updatedCart
    })),

    on(removeItemFromCart, (state, {}) => ({
      ...state
    })),

    on(removeItemFromCartSuccess, (state, {updatedCart}) => ({
      ...state,
      shoppingCart: updatedCart
    })),

    on(removeItemFromCartError, (state, {error}) => ({
      ...state,
      error: error
    })),

    on(getShoppingCart, (state, {}) => ({
      ...state
    })),

    on(getShoppingCartSuccess, (state, {shoppingCart}) => ({
      ...state,
      shoppingCart: shoppingCart
    })),

    on(getShoppingCartError, (state, {error}) => ({
      ...state,
      error: error
    })),
  )
});

export const {
  name: cartFeatureKey,
  reducer: cartReducer,
  selectShoppingCart
} = cartFeature;
