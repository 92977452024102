import {FileResponse} from "../../shared/models/file.response";
import {NamedElement} from "../../shared/models/named-element";

export class PackagingResponse {
  constructor(
    public id: number = 0,
    public price: number = 0,
    public stockAvailable: number = 0,
    public stockBought: number = 0,
    public packagingType: NamedElement = new NamedElement(),
    public quantityPerPackaging: number = 1,
    public packageImage: FileResponse = new FileResponse(),
    public productsClass: number = 0
  ) {
  }
}
