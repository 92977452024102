import {UserFlatResponse} from "../donations/user-flat.response";
import {LocationResponse} from "../../shared/models/location/location.response";
import {TagStrippedResponse} from "../../shared/models/tag-stripped.response";
import {MeasurementResponse} from "../../shared/models/measurement.response";
import {FileResponse} from "../../shared/models/file.response";

export class ProductFlatResponse {
  constructor(
    public id: number = 0,
    public creator: UserFlatResponse = new UserFlatResponse(),
    public productId: string = '',
    public name: string = '',
    public brand: string = '',
    public tags: Set<TagStrippedResponse> = new Set(),
    public qualityIndex: { id: number, type: string }[] = [],
    public measurement: MeasurementResponse = new MeasurementResponse(),
    public pricePerTradeUnit: number = 0,
    public location: LocationResponse = new LocationResponse(),
    public productImages: FileResponse[] = [],
    public lastUpdatedOn: Date = new Date()
  ) {
  }
}
