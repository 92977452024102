<div>
  <div>
    <p class="font-bold">Лице за контакт</p>
    <p class="my-2">{{ referenceDataForm?.value.recipientInfo?.firstName }}</p>
    <p class="my-2">{{ referenceDataForm?.value.recipientInfo?.lastName }}</p>
    <p class="my-2">{{ referenceDataForm?.value.recipientInfo?.email }}</p>
    <p class="my-2">{{ referenceDataForm?.value.recipientInfo?.phoneNumber }}</p>
  </div>


  <div>
    <p class="font-bold">Данни за доставка</p>
    <p class="my-2">{{ referenceDataForm?.value.deliveryMethod?.address }}</p>

    @for (details of referenceDataForm?.value.deliveryMethod?.details; track details) {
      <p class="my-2">{{ details }}</p>
    }

    <p class="my-2">{{ referenceDataForm?.value.deliveryType?.deliveryType }}</p>
  </div>

  <div>
    <p class="font-bold">Опции</p>

    @for (specifics of referenceDataForm?.value.specifics?.specifics; track specifics) {
      <p class="my-2">{{ specifics }}</p>
    }
  </div>

  <div>
    <p class="font-bold">Начин на плащане</p>
    <p class="my-2"> {{ referenceDataForm?.value.paymentMethod?.paymentMethod }}</p>
  </div>
</div>

<div>
  <h3>Уважаеми кооператори,</h3>
  <ol>
    <li>
      <span class="underline font-bold">Моля да проверите данните</span> преди да завършите поръчката. С потвърждаване на поръчката, цените на поръчаните стоки се приемат, според Справката за поръчката Ви, назависимо от евентуалните им следващи промени в БОП.
    </li>
    <li>
      <span class="underline font-bold">Бъдете точни спрямо ТРАнспортьора:</span> Много кооператори зависят от неговата и Вашата точност. Всяко Ваше закъснение с повече от 1 (една) минута се таксува с 0.30 лв за започната минута. Отброяването на закъснението се спира с Вашето появяване до ТРАнспортьора за нужните товаро-разтоварителни дейности.
    </li>
  </ol>
</div>

<p-divider type="solid"/>

<div>
  <p class="my-2">Цена на продуктите: <span class="font-bold"> {{ totalPrice | price }} лв</span></p>
  <p class="my-2">Цена на доставката: <span class="font-bold">{{ 3 | price }} лв</span></p>
  <p class="my-2">Такса над 15 килограма: <span class="font-bold">{{ 0 | price }} лв</span></p>
  <p class="my-2">Такса "Лошо време": <span class="font-bold">{{ 0 | price }} лв</span></p>
  <p class="my-2">Обща сума за Вашата поръчка: <span class="font-bold text-red-400">{{ totalPrice + 3  | price }} лв</span></p>
</div>
