<h1 class="text-2xl">Адрес за доставка</h1>

<bop-form-row-wrapper>
  <p-inputGroup>
    <p-inputGroupAddon>
      <i class="pi pi-map"></i>
    </p-inputGroupAddon>
    <bop-creatable-dropdown-single [style]="{width: '100%'}"
                                   placeholder="Адрес за доставка"
                                   [canFilter]="false"
                                   [service]="originService"
                                   (onSelect)="selectAddress($event)">
    </bop-creatable-dropdown-single>
  </p-inputGroup>
</bop-form-row-wrapper>

<form [formGroup]="deliveryMethodForm" class="pt-3 flex flex-column gap-2">
  @for (method of deliveryMethodsList; track method) {
    <label>
      <p-checkbox [value]="method"
                  formControlName="details"
                  [formControl]="deliveryMethodForm.controls.details.at(deliveryMethodsList.indexOf(method))"/>
      {{ method }}
    </label>
  }
</form>
