<section>
  <h3>От производител: {{ group.creatorName }}</h3>
  @for (item of group.items; track item) {
    <bop-market-shopping-cart-item [cartItem]="item"></bop-market-shopping-cart-item>
  }

  <div>
    <p class="my-2 text-right">Цена на продуктите: {{ group.producerTotalPrice | price }} лв</p>
    <p class="my-2 text-right">Цена на доставката: {{ 5 | price }} лв</p>
  </div>
</section>
