import {PackagingResponse} from "./packaging.response";
import {ProductFlatResponse} from "./product-flat.response";

export class ShoppingCartResponse {
  constructor(
    public id: number = 0,
    public userId: number = 0,
    public items: any = [],
    public subTotal: number = 0,
    public discounts: number = 0,
    public total: number = 0,
    public totalWeight: number = 0
  ) {
  }
}

export class ShoppingCartItemResponse {
  constructor(
    public id: number = 0,
    public item: PackagingResponse = new PackagingResponse(),
    public product: ProductFlatResponse = new ProductFlatResponse(),
    public quantity: number = 0,
    public discount: number = 0
  ) {
  }
}
