import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ButtonModule} from "primeng/button";

@Component({
  selector: 'bop-backward-forward-buttons',
  standalone: true,
  imports: [
    ButtonModule
  ],
  templateUrl: './backward-forward-buttons.component.html',
  styleUrl: './backward-forward-buttons.component.scss'
})
export class BackwardForwardButtonsComponent {
  @Input()
  public backwardText: string = 'Назад';

  @Input()
  public forwardText: string = 'Напред';

  @Input()
  public confirmText: string = 'Потвърждаване';

  @Input()
  public isFirstStep: boolean | undefined;

  @Input()
  public isLastStep: boolean | undefined;

  @Output()
  public onBackward: EventEmitter<any> = new EventEmitter();

  @Output()
  public onForward: EventEmitter<any> = new EventEmitter();

  @Output()
  public onConfirm: EventEmitter<any> = new EventEmitter();
}
