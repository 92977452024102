import {Component, Input} from '@angular/core';
import {FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {InputGroupModule} from "primeng/inputgroup";
import {InputTextModule} from "primeng/inputtext";
import {InputNumberModule} from "primeng/inputnumber";
import {InputGroupAddonModule} from "primeng/inputgroupaddon";
import {ButtonModule} from "primeng/button";

@Component({
  selector: 'bop-market-order-info-recipient',
  standalone: true,
  imports: [
    FormsModule,
    InputGroupModule,
    InputTextModule,
    InputNumberModule,
    InputGroupAddonModule,
    ReactiveFormsModule,
    ButtonModule
  ],
  templateUrl: './market-order-info-recipient.component.html',
  styleUrl: './market-order-info-recipient.component.scss'
})
export class MarketOrderInfoRecipientComponent {
  @Input() recipientInfoForm!: FormGroup
}
