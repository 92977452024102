import {PaymentMethod} from "../../shared/models/payment-method";

export enum DeliveryMethod {
  WithElevator = 'С асансьор',
  WithoutElevator = 'Без асансьор',
  AtEntrance = 'Ще сляза пред входа',
  House = 'Къща',
  LeaveToColleague = 'Моля, оставете на колега',
  LeaveToNeighbor = 'Моля, оставете на съсед',
}

export enum Specifics {
  CallIfProductIsMissing = 'Ако продуктът липсва, моля обадете се',
  CallForPromotions = 'Звъннете при наличие на промоции от производителя',
  DoNotRingBell = 'Доставчикът не звъни на звънеца',
  TELK = 'ТЕЛК',
  MotherWithChild = 'Майка с деца до 1 година',
  RecipientOver75 = 'Получателят е над 75 години',
}

export enum DeliveryType {
  Economy = 'Икономична',
  Normal = 'Нормална',
  Fast = 'Бърза',
  Urgent = 'Спешна',
}

export const paymentMethodsList: PaymentMethod[] = [
  {
    name: 'Предварително през БОП с кооперативни бонове (получаввате 3% отстъпка)',
    id: 'preliminary',
    disabled: true,
  },
  {
    name: 'При доставка в брой',
    id: 'cash',
    disabled: false,
  },
  {
    name: 'При доставка през БОП с кооперативни бонове',
    id: 'cooperativeCoupons',
    disabled: true,
  },
];
