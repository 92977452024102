import {
  ApplicationConfig,
  importProvidersFrom,
  InjectionToken,
} from '@angular/core';
import {provideRouter} from '@angular/router';

import {routes} from './app.routes';
import {provideAnimations} from "@angular/platform-browser/animations";
import {provideStore, StoreModule} from '@ngrx/store';
import {provideHttpClient, withInterceptors} from "@angular/common/http";
import {EffectsModule, provideEffects} from '@ngrx/effects';
import {authenticationFeatureKey, authenticationReducer} from "./public/authentication/store/authentication.reducer";
import {AuthenticationEffects} from "./public/authentication/store/authentication.effects";
import {MessageEffects} from "./shared/store/message/message.effects";
import {MessageService} from "primeng/api";
import {profileFeatureKey, profileReducer} from "./private/profile/store/profile.reducer";
import {ProfileEffects} from "./private/profile/store/profile.effects";
import {authenticationHeadersInterceptor} from "./shared/interceptors/auhentication-headers.interceptor";
import {locationFeatureKey, locationReducer} from "./shared/store/location/location.reducer";
import {LocationEffects} from "./shared/store/location/location.effects";
import {DonationsEffects} from "./private/donations/store/donations.effects";
import {donationFeatureKey, donationReducer} from "./private/donations/store/donations.reducer";
import {marketFeatureKey, marketReducer} from "./private/market/store/market.reducer";
import {MarketEffects} from "./private/market/store/market.effects";
import {DialogService} from "primeng/dynamicdialog";
import {cartFeatureKey, cartReducer} from "./private/market/store/cart.reducer";
import {CartEffects} from "./private/market/store/cart.effects";

export const appConfig: ApplicationConfig = {
  providers: [
    MessageService,
    DialogService,
    provideRouter(routes),
    provideAnimations(),
    provideHttpClient(withInterceptors([authenticationHeadersInterceptor])),
    provideStore(),
    provideEffects(),
    importProvidersFrom(
      StoreModule.forRoot(),
      StoreModule.forFeature(authenticationFeatureKey, authenticationReducer),
      StoreModule.forFeature(profileFeatureKey, profileReducer),
      StoreModule.forFeature(locationFeatureKey, locationReducer),
      StoreModule.forFeature(donationFeatureKey, donationReducer),
      StoreModule.forFeature(marketFeatureKey, marketReducer),
      StoreModule.forFeature(cartFeatureKey, cartReducer),
      EffectsModule.forRoot([
        AuthenticationEffects,
        ProfileEffects,
        MessageEffects,
        LocationEffects,
        DonationsEffects,
        MarketEffects,
        CartEffects
      ])),
  ]
};

export const MESSAGE_SERVICE = new InjectionToken<MessageService>('MESSAGE_SERVICE')
