import {createAction, props} from "@ngrx/store";
import {ShoppingCartResponse} from "../shopping-cart.response";
import {ShoppingCartRequest} from "../shopping-cart.request";

export const addItemToCart = createAction(
  '[Shopping_Cart_Add] Add new item to shopping cart',
  props<{ item: ShoppingCartRequest }>()
);

export const addItemToCartSuccess = createAction(
  '[Shopping_Cart_Add_Success] Add item to shopping cart successfully',
  props<{ updatedCart : ShoppingCartResponse }>()
);

export const addItemToCartError = createAction(
  '[Shopping_Cart_Add_Failure] Add item to shopping cart error',
  props<{ error: any }>()
);

export const removeItemFromCart = createAction(
  '[Shopping_Cart_Remove] Remove item from shopping cart',
  props<{ itemId: number }>()
);

export const removeItemFromCartSuccess = createAction(
  '[Shopping_Cart_Remove] Remove item from shopping cart successfully',
  props<{ updatedCart : ShoppingCartResponse }>()
);

export const removeItemFromCartError = createAction(
  '[Shopping_Cart_Remove] Remove item from shopping cart error',
  props<{ error: any }>()
);

export const getShoppingCart = createAction(
  '[Shopping_Cart_Load] Get all items from shopping cart'
);

export const getShoppingCartSuccess = createAction(
  '[Shopping_Cart_Load_Success] Get all items from shopping cart successfully',
  props<{ shoppingCart: ShoppingCartResponse }>()
);

export const getShoppingCartError = createAction(
  '[Shopping_Cart_Load_Failure] Get all items from shopping cart error',
  props<{ error: any }>()
);
