@if (allShoppingCartItems.length === 0) {
  <p class="font-italic text-yellow-300 m-4">Количката е празна</p>
} @else {
  <div class="grid m-4">
    <div class="col-8">
      @for (group of groupedShoppingCartItems; track group) {
        <bop-market-shopping-cart-producer [group]="group"></bop-market-shopping-cart-producer>
      }
    </div>
    <div class="col">
      <p-card>
        <h3>Информация за поръчката</h3>
        <p class="my-2">Обща цена на продуктите: {{ totalPrice | price }} лв</p>
        <p class="my-2">Общо килограми на продуктите: {{ totalWeight | price }} кг</p>
        <p class="my-2">Обща цена на доставката: {{ 5 | price }} лв</p>
        <p class="my-2 font-bold">Доставката е до личен адрес.</p>
        <p class="my-2 font-bold">Смяната на адрес би могло да преизчисли цената за доставка от този Производител</p>
        <div class="my-5">
          <p-button (click)="confirmShoppingCart()">Потвърди поръчката</p-button>
        </div>
      </p-card>
    </div>
  </div>
}
