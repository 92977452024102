import {Component, Input} from '@angular/core';
import {CardModule} from "primeng/card";
import {FormsModule} from "@angular/forms";
import {InputNumberModule} from "primeng/inputnumber";
import {ButtonModule} from "primeng/button";
import {ShoppingCartItemResponse} from "../../shopping-cart.response";
import {Store} from "@ngrx/store";
import {removeItemFromCartSuccess} from "../../store/cart.actions";
import {FileUrlPipe} from "../../../../shared/pipes/file-url.pipe";
import {CartService} from "../../cart.service";
import {PricePipe} from "../../../../shared/pipes/price.pipe";

@Component({
  selector: 'bop-market-shopping-cart-item',
  standalone: true,
  imports:
    [
      CardModule,
      FormsModule,
      InputNumberModule,
      ButtonModule,
      FileUrlPipe,
      PricePipe
    ],
  templateUrl: './market-shopping-cart-item.component.html',
  styleUrl: './market-shopping-cart-item.component.scss'
})
export class MarketShoppingCartItemComponent {
  @Input() public cartItem: ShoppingCartItemResponse = new ShoppingCartItemResponse();

  constructor(
    private store: Store,
    private cartService: CartService
  ) {
  }

  removeFromCart(item: ShoppingCartItemResponse) {
    this.cartService.removeItem(item.id).subscribe((res) => {
      this.store.dispatch(removeItemFromCartSuccess({updatedCart: res}));
    });
  }

  onQuantityChange(quantity: number, item: ShoppingCartItemResponse): void {
    this.cartItem = {...item, quantity: quantity};
  }
}
