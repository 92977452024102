<section class="order-wrapper">
  <p-stepper [linear]="true" #orderStepper>
    <p-stepperPanel header="Получател">
      <ng-template pTemplate="content" let-index="index" let-activateCallback="activateCallback">
        <bop-market-order-info-recipient [recipientInfoForm]="recipientInfoForm"></bop-market-order-info-recipient>
        <bop-backward-forward-buttons [isFirstStep]="true"
                                      [isLastStep]="false"
                                      class="flex pt-4 justify-content-end"
                                      (onForward)="forwardStepper(index)">
        </bop-backward-forward-buttons>
      </ng-template>
    </p-stepperPanel>
    <p-stepperPanel header="Данни за доставка">
      <ng-template pTemplate="content" let-prevCallback="prevCallback" let-index="index">
        <bop-market-order-info-delivery-method [deliveryMethodForm]="deliveryMethodForm"></bop-market-order-info-delivery-method>
        <bop-backward-forward-buttons [isFirstStep]="false"
                                      [isLastStep]="false"
                                      (onBackward)="backwardStepper(index)"
                                      (onForward)="forwardStepper(index)">
        </bop-backward-forward-buttons>
      </ng-template>
    </p-stepperPanel>
    <p-stepperPanel header="Опции">
      <ng-template pTemplate="content" let-prevCallback="prevCallback" let-index="index">
        <bop-market-order-info-options [specificsForm]="specificsForm" [deliveryTypeForm]="deliveryTypeForm"></bop-market-order-info-options>
        <bop-backward-forward-buttons [isFirstStep]="false"
                                      [isLastStep]="false"
                                      (onBackward)="backwardStepper(index)"
                                      (onForward)="forwardStepper(index)">
        </bop-backward-forward-buttons>
      </ng-template>
    </p-stepperPanel>
    <p-stepperPanel header="Начин на плащане">
      <ng-template pTemplate="content" let-prevCallback="prevCallback" let-index="index">
        <bop-market-order-info-payment-method [paymentMethodForm]="paymentMethodForm"></bop-market-order-info-payment-method>
        <bop-backward-forward-buttons [isFirstStep]="false"
                                      [isLastStep]="false"
                                      (onBackward)="backwardStepper(index)"
                                      (onForward)="combineAllFormsData(index)">
        </bop-backward-forward-buttons>
      </ng-template>
    </p-stepperPanel>
    <p-stepperPanel header="Справка за поръчката ви">
      <ng-template pTemplate="content" let-prevCallback="prevCallback" let-index="index">
        <bop-market-order-info-reference [referenceDataForm]="referenceDataForm"
                                         [totalPrice]="totalPrice">
        </bop-market-order-info-reference>
        <bop-backward-forward-buttons [isFirstStep]="false"
                                      [isLastStep]="true"
                                      (onBackward)="backwardStepper(index)"
                                      (onConfirm)="confirmOrder()">
        </bop-backward-forward-buttons>
      </ng-template>
    </p-stepperPanel>
  </p-stepper>
</section>
