import {RoleRequest} from "../../../private/profile/profile.request";
import {LocationResponse} from "./location.response";

export class CreateLocationRequest {
  constructor(
    public address: string = '',
    public comment: string = '',
    public coordinates: string = '',
    public settlementId: number = 0,
    public residentialAreaId: number = 0,
    public locationRoles: RoleRequest[] = [],
    public locationImageId: number | null = null
  ) {

  }

  static fromResponse(res: LocationResponse) {
    return new CreateLocationRequest(
      res.address,
      res.comment,
      res.coordinates,
      res.settlement.id,
      res.residentialArea?.id,
      res.locationRoles.map(
        role => new RoleRequest(role.roleId, role.reason)
      ),
      res.locationImage?.id
    )
  }
}
