import {Component} from '@angular/core';
import {TableModule} from "primeng/table";
import {FormsModule} from "@angular/forms";
import {InputTextModule} from "primeng/inputtext";

@Component({
  selector: 'bop-market-orders-to-transport',
  standalone: true,
  imports: [
    TableModule,
    FormsModule,
    InputTextModule
  ],
  templateUrl: './market-orders-to-transport.component.html',
  styleUrl: './market-orders-to-transport.component.scss'
})
export class MarketOrdersToTransportComponent {
  allOrdersToTransport: Array<any> = [
    {
      id: 1,
      items: [
        {
          id: 1,
          product: {
            id: 1,
            creator: {
              id: 1,
              firstName: "Димитър",
              lastName: "Марков"
            },
            productId: 78,
            name: "Риган",
            brand: "Био Билки",
          },
          quantity: 7
        },
        {
          id: 4,
          product: {
            id: 4,
            creator: {
              id: 1,
              firstName: "Димитър",
              lastName: "Марков"
            },
            productId: 80,
            name: "Босилек",
            brand: "Био Билки",
          },
          quantity: 9
        }
      ],
      deliveryAddress: {
        id: 1,
        userId: 2,
        address: "гр София, ул Иван Вазов 2, ет 5, ап 55",
        deliveryType: "Нормална",
        comments: "Ако продуктът липсва, моля обадете се",
        paymentMethod: "При доставка в брой"
      },
      merchant: {
        firstName: "Стоян",
        lastName: "Стоянов"
      }
    },
    {
      id: 2,
      items: [
        {
          id: 2,
          product: {
            id: 2,
            creator: {
              id: 2,
              firstName: "Чавдар",
              lastName: "Иванов"
            },
            productId: 78,
            name: "Мед",
            brand: "Био Продукти",
          },
          quantity: 2
        }
      ],
      deliveryAddress: {
        id: 1,
        userId: 2,
        address: "гр София, ул Иван Вазов 2, ет 5, ап 55",
        deliveryType: "Нормална",
        comments: "Ако продуктът липсва, моля обадете се",
        paymentMethod: "При доставка в брой"
      },
      merchant: {
        firstName: "Стоян",
        lastName: "Стоянов"
      }
    }
  ];
}
