<form [formGroup]="recipientInfoForm" class="flex flex-column gap-3">
  <h1 class="text-2xl">Допълване на данните</h1>
    <p-inputGroup>
      <p-inputGroupAddon>
        <i class="pi pi-user"></i>
      </p-inputGroupAddon>
      <input pInputText formControlName="firstName" placeholder="Име"/>
    </p-inputGroup>
    <p-inputGroup>
      <p-inputGroupAddon>
        <i class="pi pi-user"></i>
      </p-inputGroupAddon>
      <input type="text" pInputText formControlName="lastName" placeholder="Фамилия"/>
    </p-inputGroup>
    <p-inputGroup>
      <p-inputGroupAddon>
        <i class="pi pi-send"></i>
      </p-inputGroupAddon>
      <input pInputText formControlName="email" placeholder="Имейл адрес"/>
    </p-inputGroup>
    <p-inputGroup>
      <p-inputGroupAddon>
        <i class="pi pi-phone"></i>
      </p-inputGroupAddon>
      <input pInputText placeholder="Телефон 1" />
    </p-inputGroup>
</form>
