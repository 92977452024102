export class ProfileRequest {
  constructor(
    public password: string = '',
    public firstName: string = '',
    public middleName: string = '',
    public lastName: string = '',
    public email: string = '',
    public locations: number[] = [],
    public bopSoftware: string = '',
    public wantToSell: string = '',
    public wantToBuy: string = '',
    public wantToExchange: string = '',
    public publicProfile: boolean = false,
    public userRoles: RoleRequest[] = [],
    public phoneNumbers: PhoneNumberRequest[] = [],
  ) {
  }
}

export class RoleRequest {
  constructor(
    public roleId: number = 0,
    public reason = ''
  ) {
  }
}

export class PhoneNumberRequest {
  constructor(
    public id: number | null = null,
    public number = ''
  ) {
  }
}

