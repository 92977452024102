<p-table [value]="packagings">
  <ng-template pTemplate="header">
    <tr>
      <th>Снимка</th>
      <th>Тип опаковка</th>
      <th>Количество в опаковката</th>
      <th>Клас</th>
      <th>Наличен брой</th>
      <th>Действия</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-packaging>
    <tr>
      <td><img class="h-4rem" [src]="packaging.packageImage.url | fileUrl" alt="packaging"></td>
      <td>{{ packaging.packagingType.name }}</td>
      <td>{{ packaging.quantityPerPackaging }}</td>
      <td>
        <bop-product-class></bop-product-class>
      </td>
      <td>{{ packaging.stockAvailable }}</td>
      <td class="flex align-items-center">
        <p-inputNumber class="mr-2"
                       [(ngModel)]="quantityValue"
                       [showButtons]="true"
                       [min]="1"
                       [max]="packaging.stockAvailable"
                       [inputStyle]="{width: '5rem'}"
        >
        </p-inputNumber>
        <p-button icon="pi pi-shopping-cart" (click)="addToCart(packaging)"></p-button>
<!--        <p-button icon="pi pi-pencil" (onClick)="packagingEdit.emit(packaging)"></p-button>-->
      </td>
    </tr>
  </ng-template>
</p-table>
