import {Component, CUSTOM_ELEMENTS_SCHEMA, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RadioButtonModule} from "primeng/radiobutton";
import {paymentMethodsList} from "../../order-stepper.constants";

@Component({
  selector: 'bop-market-order-info-payment-method',
  standalone: true,
  imports: [
    FormsModule,
    RadioButtonModule,
    ReactiveFormsModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './market-order-info-payment-method.component.html',
  styleUrl: './market-order-info-payment-method.component.scss'
})
export class MarketOrderInfoPaymentMethodComponent implements OnInit {
  @Input() paymentMethodForm!: FormGroup
  protected readonly paymentMethodsList = paymentMethodsList;

  ngOnInit() {
    this.paymentMethodsList.forEach((method) => {
      const control = new FormControl(
        { value: null, disabled: method.disabled }
      );

      this.paymentMethodForm.addControl(method.id, control);
    });
  }

  selectPaymentMethod(payment: string) {
    this.paymentMethodForm.controls['paymentMethod'].setValue(payment);
  }
}
