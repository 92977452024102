<section class="px-7 py-3">
  <section class="flex flex-column align-items-center md:align-items-start md:flex-row">
    <p-galleria
      [value]="product.productImages"
      [containerStyle]="{ 'max-width': '640px', 'width': '25rem' }"
      [numVisible]="2">
      <ng-template pTemplate="item" let-item>
        <img [src]="item.url | fileUrl" class="h-20rem w-full" [style]="{'object-fit': 'cover'}"/>
      </ng-template>
      <ng-template pTemplate="thumbnail" let-item>
        <div class="grid grid-nogutter justify-content-center">
          <img class="h-5rem w-5rem" [style]="{'object-fit': 'cover'}" [src]="item.url | fileUrl"/>
        </div>
      </ng-template>
    </p-galleria>

    <div class="ml-5">
      <h1 class="text-xl">{{ product.name }}</h1>
      <div>
        <p><strong>Качество: </strong> {{ product.qualityIndex }}</p>
        <p><strong>Марка: </strong> {{ product.brand }}</p>
        <p><strong>Категории: </strong>
          @for (category of categoriesNames; track category) {
            <span>{{ category }}, </span>
          }
        </p>
        <p><strong>Тагове: </strong>
          @for (tag of tagsNames; track tag) {
            <span>{{ tag }}, </span>
          }
        </p>
      </div>
    </div>
  </section>

  <p-accordion [multiple]="true" [activeIndex]="[0]">
    <p-accordionTab header="Разфасофки">
      <bop-market-packagings-list
        [packagings]="product.packagings"
      ></bop-market-packagings-list>
    </p-accordionTab>

    <p-accordionTab header="За продукта">
      <p><strong>Мярка:</strong> {{ product.measurement.name }}</p>
      <p><strong>Произход:</strong> {{ product.originLocation.settlement.name }}</p>
      <p><strong>Може да се обаждате по телефона:</strong> {{ product.phoneCallAvailability }}</p>
      <p><strong>Вибрационни изисквания:</strong> {{ product.vibrationRequirements }}</p>
      <p><strong>Други изисквания:</strong> {{ product.otherRequirements }}</p>
      <p><strong>Приемани валути: </strong>
        @for (currency of product.exchangeCurrencies; track currency) {
          <span>{{currency}}, </span>
        }
      </p>
    </p-accordionTab>

    <p-accordionTab header="Състав">
      <div [innerHTML]="product.ingredients"></div>
    </p-accordionTab>

    <p-accordionTab header="Технология на производство">
      <div [innerHTML]="product.producingTechnology"></div>
    </p-accordionTab>

    <p-accordionTab header="С какво продуктът е уникален?">
      <div [innerHTML]="product.productsUniqueness"></div>
    </p-accordionTab>

    <p-accordionTab header="Предстоящи мероприятия">
      <div [innerHTML]="product.upcomingEvents"></div>
    </p-accordionTab>

    <p-accordionTab header="Приложение на продукта">
      <div [innerHTML]="product.productsApplication"></div>
    </p-accordionTab>
  </p-accordion>

</section>
