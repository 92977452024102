@if (profileItems?.length) {
  <p-menubar class="fixed z-5 w-full">
    <ng-template pTemplate="start">
      <p-button class="md:hidden" icon="pi pi-angle-right" [rounded]="true" [text]="true"
                (click)="this.sidebarVisibility.emit(true)"></p-button>

      <bop-header-profile-statistics
        [profileMeta]="profileMeta"
        [profileItems]="profileItems"
        [theme]="theme"
        (themeChange)="themeChange.emit($event)">
      </bop-header-profile-statistics>

      <div class="shopping-cart-wrapper">
        <p-button icon="pi pi-shopping-cart" class="shopping-cart-btn" [rounded]="true"
                  (click)="goToShoppingCart()">
          @if (shoppingCartItems !== '0') {
            <p-badge class="shopping-cart-badge" [value]="shoppingCartItems" severity="danger"/>
          }
        </p-button>
      </div>

      @for (menuItem of menuItems; track menuItem) {
      <p-button severity="contrast"
                outlined="true"
                class="mx-1 min-w-5em"
                iconPos="left"
                routerLinkActive="active-custom-menu-button"
                (onClick)="callMenuItemCommand(menuItem.command)"
                [icon]="menuItem.icon"
                [routerLink]="menuItem.routerLink"
                [label]="menuItem.label"
                [style]="menuItem.style">
      </p-button>
      }
    </ng-template>
  </p-menubar>

  <section class="spacer w-full h-4rem"></section>
}

