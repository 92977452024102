import {Component, CUSTOM_ELEMENTS_SCHEMA, Input} from '@angular/core';
import {CardModule} from "primeng/card";
import {ButtonModule} from "primeng/button";
import {MarketShoppingCartItemComponent} from "../market-shopping-cart-item/market-shopping-cart-item.component";
import {PricePipe} from "../../../../shared/pipes/price.pipe";

@Component({
  selector: 'bop-market-shopping-cart-producer',
  standalone: true,
  imports: [
    CardModule,
    ButtonModule,
    MarketShoppingCartItemComponent,
    PricePipe
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './market-shopping-cart-producer.component.html',
  styleUrl: './market-shopping-cart-producer.component.scss'
})
export class MarketShoppingCartProducerComponent {
  @Input() group!: any;
}
