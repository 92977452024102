import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {
  getShoppingCartError,
  getShoppingCart,
  getShoppingCartSuccess,
  addItemToCart,
  addItemToCartSuccess,
  addItemToCartError,
  removeItemFromCart,
  removeItemFromCartSuccess, removeItemFromCartError
} from "./cart.actions";
import {catchError, concatMap, map, of, switchMap, take} from "rxjs";
import {CartService} from "../cart.service";
import {ShoppingCartItemResponse, ShoppingCartResponse} from "../shopping-cart.response";
import {selectShoppingCart} from "./cart.reducer";
import {Store} from "@ngrx/store";

@Injectable()
export class CartEffects {
  constructor(private actions$: Actions,
              private store: Store,
              private cartService: CartService) {
  }

  public loadShoppingCart = createEffect(() =>
    this.actions$.pipe(
      ofType(getShoppingCart),
      switchMap(() => {
        return this.cartService.getItems().pipe(
          map((shoppingCart) => getShoppingCartSuccess({shoppingCart})),
          catchError((error) => of(getShoppingCartError({error})))
        );
      })
    )
  );

  public addItemToShoppingCart = createEffect(() =>
    this.actions$.pipe(
      ofType(addItemToCart),
      concatMap(({ item }) => {
        return this.store.select(selectShoppingCart).pipe(
          take(1),
          switchMap((shoppingCart) => {
            const existingItem = shoppingCart.items.find(
              (cartItem: ShoppingCartItemResponse) => cartItem.item.id === item.packagingId
            );

            if (existingItem) {
              const updatedItem = {
                ...existingItem,
                quantity: existingItem.quantity + item.quantity,
              };

              return this.cartService.updateItem(existingItem.id, updatedItem).pipe(
                map((res) => addItemToCartSuccess({updatedCart: res})),
                catchError((error) => of(addItemToCartError({error})))
              );
            } else {
              return this.cartService.addItem(item).pipe(
                map((res) => addItemToCartSuccess({updatedCart: res})),
                catchError((error) => of(addItemToCartError({error})))
              );
            }
          })
        );
      })
    )
  );

  public removeItemFromShoppingCart = createEffect(() =>
    this.actions$.pipe(
      ofType(removeItemFromCart),
      switchMap(({ itemId }) =>
        this.cartService.removeItem(itemId).pipe(
          map((res: ShoppingCartResponse) => removeItemFromCartSuccess({updatedCart: res})),
          catchError((error) => of(removeItemFromCartError({error})))
        )
      )
    )
  );


}
