import {Component, OnInit} from '@angular/core';
import {Router, RouterOutlet} from "@angular/router";
import {CardModule} from "primeng/card";
import {InputNumberModule} from "primeng/inputnumber";
import {FormsModule} from "@angular/forms";
import {ButtonModule} from "primeng/button";
import {Store} from "@ngrx/store";
import {selectShoppingCart} from "../store/cart.reducer";
import {ShoppingCartItemResponse} from "../shopping-cart.response";
import {getShoppingCart} from "../store/cart.actions";
import {PricePipe} from "../../../shared/pipes/price.pipe";
import {
  MarketShoppingCartProducerComponent
} from "./market-shopping-cart-producer/market-shopping-cart-producer.component";

@Component({
  selector: 'bop-market-shopping-cart',
  standalone: true,
  imports: [
    RouterOutlet,
    CardModule,
    InputNumberModule,
    FormsModule,
    ButtonModule,
    PricePipe,
    MarketShoppingCartProducerComponent
  ],
  templateUrl: './market-shopping-cart.component.html',
  styleUrl: './market-shopping-cart.component.scss'
})
export class MarketShoppingCartComponent implements OnInit{
  public value: number = 1;
  public allShoppingCartItems: ShoppingCartItemResponse[] = [];
  public totalPrice: number = 0;
  public totalWeight: number = 0;

  public groupedShoppingCartItems: any[] = [];

  constructor(
    private store: Store,
    private router: Router
  ) {
  }

  public ngOnInit() {
    this.store.dispatch(getShoppingCart());

    this.store.select(selectShoppingCart).subscribe((cart) => {
      if (cart) {
        this.allShoppingCartItems = cart.items;
        this.totalPrice = cart.total;
        this.totalWeight = cart.totalWeight;
        this.groupShoppingCartItemsByProducer(cart.items);
      }
    });
  }

  groupShoppingCartItemsByProducer(cartItems: ShoppingCartItemResponse[]): void {
    const groupedObject = cartItems.reduce(
      (acc: { [key: number]: { creatorName: string; items: any[], producerTotalPrice: number } }, item) => {
        const creatorId = item.product.creator.id;
        const creatorName = `${item.product.creator.firstName} ${item.product.creator.lastName}`;
        const itemTotalPrice = item.item.price * item.quantity;

        if (!acc[creatorId]) {
          acc[creatorId] = {
            creatorName,
            items: [],
            producerTotalPrice: 0
          };
        }

        acc[creatorId].items.push(item);
        acc[creatorId].producerTotalPrice += itemTotalPrice;

        return acc;
      },
      {}
    );

    this.groupedShoppingCartItems = Object.entries(groupedObject).map(([creatorId, group]) => ({
      creatorId: +creatorId,
      creatorName: group.creatorName,
      items: group.items,
      producerTotalPrice: group.producerTotalPrice
    }));

    console.log(this.groupedShoppingCartItems);
  }

  confirmShoppingCart() {
    this.router.navigate(['/private/market/order-info']).then();
  }
}
