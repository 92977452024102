import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MenuItem, MenuItemCommandEvent} from "primeng/api";
import {Theme, ThemeType} from "../../models/theme.type";
import {MenubarModule} from "primeng/menubar";
import {MenuModule} from "primeng/menu";
import {ButtonModule} from "primeng/button";
import {UserStatisticsComponent} from "../../../private/profile/user-statistics/user-statistics.component";
import {StatisticsResponse} from "../../models/statistics.response";
import {DividerModule} from "primeng/divider";
import {HeaderProfileStatisticsComponent} from "./header-profile-statistics/header-profile-statistics.component";
import {Router} from "@angular/router";
import {BadgeModule} from 'primeng/badge';
import {Store} from "@ngrx/store";
import {selectShoppingCart} from "../../../private/market/store/cart.reducer";

@Component({
  selector: 'bop-header',
  standalone: true,
  imports: [
    MenubarModule,
    MenuModule,
    ButtonModule,
    UserStatisticsComponent,
    DividerModule,
    HeaderProfileStatisticsComponent,
    BadgeModule
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit{
  @Input() menuItems: MenuItem[] | undefined;
  @Input() profileItems: MenuItem[] | undefined;
  @Input() profileMeta: StatisticsResponse | undefined;
  @Input() theme: Theme = ThemeType.LIGHT();

  @Output() themeChange: EventEmitter<Theme> = new EventEmitter();
  @Output() sidebarVisibility: EventEmitter<boolean> = new EventEmitter();

  public shoppingCartItems: string = '';

  constructor(
    private router: Router,
    private store: Store
  ) {
  }

  public ngOnInit() {
    this.store.select(selectShoppingCart).subscribe((shoppingCart) => {
      this.shoppingCartItems = shoppingCart.items.length.toString();
    });
  }

  callMenuItemCommand(command: ((event: MenuItemCommandEvent) => void) | undefined) {
    if (command) {
      command({index: 0, item: undefined, originalEvent: undefined})
    }
  }

  goToShoppingCart() {
    this.router.navigate(['/private/market/shopping-cart']).then();
  }
}

