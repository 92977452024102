import {Component, Input} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {DividerModule} from "primeng/divider";
import {PricePipe} from "../../../../shared/pipes/price.pipe";

@Component({
  selector: 'bop-market-order-info-reference',
  standalone: true,
  imports: [
    DividerModule,
    PricePipe
  ],
  templateUrl: './market-order-info-reference.component.html',
  styleUrl: './market-order-info-reference.component.scss'
})
export class MarketOrderInfoReferenceComponent {
  @Input() referenceDataForm: FormGroup | undefined;
  @Input() totalPrice: number = 0;
}
